import React, {useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {cssFont, cssShadow, cssText} from '@ohoareau/css-utils';
import {
    digital_album_product, useLuniiTranslation, Modal, Img, Checkbox, DynamicIcon, useLuniiUser,
    useSubscriptionSpendCreditSubmit, useLuniiEventTracking, useHasMounted, ErrorPanel, useShoppingCart, useLuniiPage
} from '../../../ui';
import {DeviceTag, Button} from '.';
import {getTargetDevice} from '../../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '510px !important',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100% !important',
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_2', undefined, undefined, '#2C3637'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'body_thick'),
        },
    },
    content: {
        padding: '0px 8px 24px 8px',
        position: 'relative',
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: '#F2F4F5',
        marginBottom: theme.spacing(2),
    },
    item: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    itemImage: {
        width: 96,
        maxWidth: 96,
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        width: 96,
        maxWidth: 96,
        backgroundColor: '#faf9f8',
        ...cssShadow(theme, 'level_02'),
        height: 'auto',
    },
    itemProperties: {
        flex: 1,
        padding: theme.spacing(0, 2),
    },
    productTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    locale: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#7C878B'),
        margin: '2px 0',
    },
    tag: {
      marginTop: 4,
    },
    actualCredits: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        marginBottom: 2,
    },
    creditSvg: {
        marginLeft: 2,
    },
    afterCredits: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    checkbox: {
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#2762E9'),
        margin: 1.5,
    },
    disclaimer: {
        marginTop: 8,
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#7C878B'),
    },
    actions: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'flex-end',
        '&>:first-child': {
            marginRight: theme.spacing(2),
        }
    },
    successContent: {
        textAlign: 'center',
    },
    successTitle: {
        width: '90%',
        margin: '0 auto',
    },
    successDescription: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#5F6769'),
        width: '90%',
        margin: '8px auto 16px auto',
    },
    svgSuccess: {
        backgroundColor: '#018B88',
        borderRadius: '50%',
        color: 'white',
        padding: 4,
        marginBottom: 48,
    },
    subscriptionButton: {
      width: '100%',
    },
    error: {
        margin: theme.spacing(2, 0, 0, 0),
    }
}));

export function ProductSubscriptionModal({className = '', product, open = false, onClose = () => {}}: ProductSubscriptionModalProps) {
    const classes = useStyles();
    const mode: 'fah_flam' | 'fah' | 'flam' | undefined = getTargetDevice(product.deviceTarget);
    const {t} = useLuniiTranslation();
    const hasMounted = useHasMounted();
    const {user, setUser, refreshUserPartial} = useLuniiUser();
    const [loading, setLoading] = useState<boolean>(false);
    const [checkboxSelected, setCheckboxSelected] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const handleTrackingEvent = useLuniiEventTracking();
    const [{deleteCartItem}] = useShoppingCart();
    const {...p} = useLuniiPage();
    const [err, setErr] = useState<any>(undefined);
    const [spendCredit] = useSubscriptionSpendCreditSubmit(async ({result, error}: any) => {
        if (result?.success) {
            setUser({...user, ownedPacksReferences: Array.from(new Set([
                    ...(user || {}).ownedPacksReferences,
                    product.reference,
                ]))});
            await refreshUserPartial({library: true, subscription: true});
            setSuccess(true);
            deleteCartItem(product.id as string);
            handleTrackingEvent({
                categorie: 'interaction',
                action: 'abonnement',
                libelle: `utilisation - ${product?.reference} - ${product?.name}`,
            });
        }
        if (error) setErr(error);
        setLoading(false);
    });
    if (!hasMounted || !user) return null;
    const handleClose = () => {
        onClose();
        if (success) setSuccess(false);
        if (checkboxSelected) setCheckboxSelected(false);
        if (err) setErr(undefined);
    }
    const handleValidate = async () => {
        setLoading(true);
        setErr(undefined);
        await spendCredit({
            productReference: product.reference,
            locale: user?.locale,
            currency: product.currency,
        });
    }
    const balance = user?.subscription?.wallet?.balance || 0;
    const basicBody = (
        <div className={classes.content}>
            <div className={classes.item}>
                {product.image && (
                    <div className={classes.itemImage}>
                        <Img loading='eager' className={classes.image} rounded={false} {...product.image}/>
                    </div>
                ) || false}
                <div className={classes.itemProperties}>
                    <div className={classes.productTitle}>{product.name}</div>
                    {product.locale && <div className={classes.locale}>{t(`locale_${product.locale.toLowerCase()}_product_language`)}</div> || false}
                    {product?.deviceTarget?.length && <div className={classes.tag}><DeviceTag size='xsmall' deviceTarget={product.deviceTarget}/></div> || false}
                </div>
            </div>
            <div className={classes.separator}/>
            <div className={classes.actualCredits}>
                {t('subscriptionspendmodal_balance_current', {credit: balance})}
            </div>
            <div className={classes.afterCredits}>
                <div>{t('subscriptionspendmodal_balance_after_purchase', {credit: balance - 1})}</div>
                <DynamicIcon type='credit-24' className={classes.creditSvg} width={24} height={24}/>
            </div>
            <Checkbox
                id='subscription_confirm_usage'
                name='subscription_confirm_usage'
                status={checkboxSelected}
                content={t('subscriptionspendmodal_confirm_usage')}
                linkTarget={(e: any) => setCheckboxSelected(e.target.checked)}
                onChange={(e: any) => setCheckboxSelected(e.target.checked)}
                textClassName={classes.checkbox}
                alignItems='flex-start'
            />
            <div className={classes.disclaimer}>{t(`product_subscription_modal_disclaimer_${mode}`)}</div>
            {err && <ErrorPanel className={classes.error} error={err} group='subscription' locale={p?.locale}/> || false}
            <div className={classes.actions}>
                <Button url={handleClose} text={t('button_label_cancel')} theme='secondary'/>
                <Button url={handleValidate} text={t('button_label_validate')} disabled={!checkboxSelected} loading={loading}/>
            </div>
        </div>
    );
    const successBody = (
        <div className={clsx(classes.content, classes.successContent)}>
            <div className={clsx(classes.title, classes.successTitle)}>{t('subscriptionspendmodal_success_title')}</div>
            <div className={classes.successDescription}>{product.customBook ? t('custom_book_product_owned_description') : t(`product_subscription_modal_success_${mode}`)}</div>
            <div><DynamicIcon type='check-16' width={32} height={32} className={classes.svgSuccess}/></div>
            <Button className={classes.subscriptionButton} url={handleClose} text={t('subscriptionspendmodal_success_close')}/>
        </div>
    );
    return (
        <Modal
            className={clsx(className, classes.root)}
            open={open}
            titleClassName={classes.title}
            onCloseCallback={handleClose}
            overlay={loading}
            {...!success && {title: t('subscriptionspendmodal_title', {credit: 1})}}
            body={success && successBody || basicBody}
        />
    );
}

export interface ProductSubscriptionModalProps {
    className?: string;
    product: digital_album_product;
    open?: boolean;
    onClose?: Function;
}

export default ProductSubscriptionModal;
